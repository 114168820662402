import { AnimatePresence, m } from 'framer-motion';
import { Fragment, useState, useEffect } from 'react';
import { opacityAnimation } from '@common/ui/animation/opacity-animation';
import { Skeleton } from '@common/ui/skeleton/skeleton';
import { useProducts } from '@common/billing/pricing-table/use-products';
import { Product } from '@common/billing/product';
import { findBestPrice, UpsellBillingCycle } from '@common/billing/pricing-table/find-best-price';
import { useAuth } from '@common/auth/use-auth';
import clsx from 'clsx';
import { Chip } from '@common/ui/forms/input-field/chip-field/chip';
import { Trans } from '@common/i18n/trans';
import { FormattedPrice } from '@common/i18n/formatted-price';
import { Button } from '@common/ui/buttons/button';
import { Link } from 'react-router-dom';
import { setInLocalStorage } from '@common/utils/hooks/local-storage';
import { ProductFeatureList } from '@common/billing/pricing-table/product-feature-list';
import { apiClient } from '@common/http/query-client';

interface PricingTableProps {
  selectedCycle: UpsellBillingCycle;
  className?: string;
  productLoader?: string;
}

export function PricingTable({
  selectedCycle,
  className,
  productLoader,
}: PricingTableProps) {
  const query = useProducts(productLoader);
  return (
    <div
      className={clsx(
        'flex flex-col items-stretch gap-24 overflow-x-auto overflow-y-visible pb-20 md:flex-row md:justify-center',
        className,
      )}
    >
      <AnimatePresence initial={false} mode="wait">
        {query.data ? (
          <PlanList key="plan-list" plans={query.data.products} selectedPeriod={selectedCycle} />
        ) : (
          <SkeletonLoader key="skeleton-loader" />
        )}
      </AnimatePresence>
    </div>
  );
}

interface PlanListProps {
  plans: Product[];
  selectedPeriod: UpsellBillingCycle;
}

function PlanList({ plans, selectedPeriod }: PlanListProps) {
  const { isLoggedIn, isSubscribed } = useAuth();
  const filteredPlans = plans.filter(plan => !plan.hidden);
  const [discountedPrices, setDiscountedPrices] = useState<{ [key: number]: number | null }>({});

  const handleDiscountUpdate = (planId: number, discountValue: number, discountType: string, originalAmount: number, isTrial: boolean, trialAmount: number) => {
    let finalPrice = isTrial ? trialAmount : originalAmount;
    if (discountType === 'percentage') {
      finalPrice -= (finalPrice * discountValue) / 100;
    } else if (discountType === 'fixed') {
      finalPrice -= discountValue;
    }
    setDiscountedPrices(prev => ({ ...prev, [planId]: Math.max(0, finalPrice) }));
  };

  return (
    <Fragment>
      {filteredPlans.map((plan, index) => {
        const isFirst = index === 0;
        const isLast = index === filteredPlans.length - 1;
        const price = findBestPrice(selectedPeriod, plan.prices);
        const isTrial = plan.kidson_trial_enable === '1';
        const trialAmount = parseFloat(plan.kidson_trial_amount ?? '0');
        const discountedPrice = discountedPrices[plan.id] ?? null;

        let upgradeRoute: string = '';
        if (!isLoggedIn) {
          upgradeRoute = `/register?redirectFrom=pricing`;
        } else if (isSubscribed) {
          upgradeRoute = `/change-plan/${plan.id}/${price?.id}/confirm`;
        } else if (isLoggedIn && !plan.free) {
          upgradeRoute = `/checkout/${plan.id}/${price?.id}`;
        }

        const [finalUpgradeRoute, setFinalUpgradeRoute] = useState(upgradeRoute);

        return (
          <m.div
            key={plan.id}
            {...opacityAnimation}
            className={clsx(
              'w-full rounded-panel border bg px-28 py-28 shadow-lg md:min-w-240 md:max-w-350',
              isFirst && 'ml-auto',
              isLast && 'mr-auto',
            )}
          >
            <div className="mb-32">
              <Chip
                radius="rounded"
                size="sm"
                className={clsx('mb-20 w-min', !plan.recommended && 'invisible')}
              >
                <Trans message="Most popular" />
              </Chip>
              <div className="mb-12 text-xl font-semibold">
                <Trans message={plan.name} />
              </div>
              <div className="text-sm text-muted">
                <Trans message={plan.description} />
              </div>
            </div>
            <div>
              {isTrial ? (
                discountedPrice !== null ? (
                  <div>
                    <div className="text-xl font-bold line-through text-muted">
                      <Trans message={`${trialAmount.toFixed(2)} €`} />
                    </div>
                    <div className="text-4xl font-bold">
                      <Trans message={`${discountedPrice.toFixed(2)} €`} />
                    </div>
                    <div className="text-sm text-muted">
                      <Trans message={`Par pirmajām ${plan.kidson_trial_period} dienām`} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="text-4xl font-bold">
                      <Trans message={`${trialAmount.toFixed(2)} €`} />
                    </div>
                    <div className="text-sm text-muted">
                      <Trans message={`Par pirmajām ${plan.kidson_trial_period} dienām`} />
                    </div>
                  </div>
                )
              ) : discountedPrice !== null ? (
                <div>
                  <div className="text-xl font-bold line-through text-muted">
                    <Trans message={`${price?.amount?.toFixed(2) ?? '0.00'} €`} />
                  </div>
                  <div className="text-4xl font-bold">
                    <Trans message={`${discountedPrice.toFixed(2)} €`} />
                  </div>
                </div>
              ) : (
                price && (
                  <FormattedPrice
                    priceClassName="font-bold text-4xl"
                    periodClassName="text-muted text-xs"
                    variant="separateLine"
                    price={price}
                    className="mt-32"
                  />
                )
              )}
              <CouponInput
                productId={plan.id}
                upgradeRoute={upgradeRoute}
                onRouteUpdate={setFinalUpgradeRoute}
                onDiscountUpdate={(discountValue: number, discountType: string) =>
                  handleDiscountUpdate(plan.id, discountValue, discountType, price?.amount ?? 0, isTrial, trialAmount)
                }
              />
              <div className="mt-30">
                <Button
                  variant={plan.recommended ? 'flat' : 'outline'}
                  color="primary"
                  className="w-full"
                  size="md"
                  elementType={finalUpgradeRoute ? Link : undefined}
                  disabled={!finalUpgradeRoute}
                  onClick={() => {
                    if (isLoggedIn || !price || !plan) return;
                    setInLocalStorage('be.onboarding.selected', {
                      productId: plan.id,
                      priceId: price.id,
                    });
                  }}
                  to={finalUpgradeRoute}
                >
                  {plan.free ? <Trans message="Get started" /> : <Trans message="Upgrade" />}
                </Button>
              </div>
              <ProductFeatureList product={plan} />
            </div>
          </m.div>
        );
      })}
    </Fragment>
  );
}

interface CouponInputProps {
  productId: string | number;
  upgradeRoute: string;
  onRouteUpdate: (route: string) => void;
  onDiscountUpdate: (discountValue: number, discountType: string) => void;
}

function CouponInput({ productId, upgradeRoute, onRouteUpdate, onDiscountUpdate }: CouponInputProps) {
  const [localCouponCode, setLocalCouponCode] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleValidateCoupon = async () => {
    if (!localCouponCode) return;

    try {
      const response = await apiClient.post('coupons/validate', {
        coupon_code: localCouponCode,
        product_id: productId,
      });

      if (response.data.coupon_id) {
        setErrorMessage(null);
        setSuccessMessage('Kupons veiksmīgi pievienots!');
        const newRoute = `${upgradeRoute}/${response.data.coupon_id}`;
        onRouteUpdate(newRoute);
        onDiscountUpdate(response.data.discount, response.data.discount_type);
      } else {
        setSuccessMessage(null);
        setErrorMessage(response.data.message || 'Kupons nav derīgs vai beidzies derīguma termiņš');
        onRouteUpdate(upgradeRoute);
      }
    } catch (err) {
      setSuccessMessage(null);
      const errorMessage = (err as any).response?.data?.message || 'Kļūda, apstiprinot kuponu';
      setErrorMessage(errorMessage);
      onRouteUpdate(upgradeRoute);
    }
  };

  return (
    <div className="mt-30">
      <div className="text-sm font-semibold mb-12">
        <Trans message="Vai Tev ir kupona kods?" />
      </div>
      <input
        type="text"
        id={`coupon-${productId}`}
        value={localCouponCode}
        onChange={(e) => setLocalCouponCode(e.target.value)}
        className="w-full p-2 border rounded mb-12 text-sm"
        placeholder="Ievadi kodu"
      />
      <Button
        onClick={handleValidateCoupon}
        className="w-full"
        size="md"
        variant="outline"
        color="primary"
      >
        <Trans message="Pievienot" />
      </Button>
      {successMessage && <div className="text-sm text-center mt-2">{successMessage}</div>}
      {errorMessage && <div className="text-sm text-center mt-2">{errorMessage}</div>}
    </div>
  );
}

function SkeletonLoader() {
  return (
    <Fragment>
      <PlanSkeleton key="skeleton-1" />
      <PlanSkeleton key="skeleton-2" />
      <PlanSkeleton key="skeleton-3" />
    </Fragment>
  );
}

function PlanSkeleton() {
  return (
    <m.div {...opacityAnimation} className="w-full rounded-lg border px-28 py-90 shadow-lg md:max-w-350">
      <Skeleton className="my-10" />
      <Skeleton className="mb-40" />
      <Skeleton className="mb-40 h-30" />
      <Skeleton className="mb-40 h-40" />
      <Skeleton className="mb-20" />
      <Skeleton />
      <Skeleton />
    </m.div>
  );
}
